import { sendGTMEvent } from "@next/third-parties/google";
import DOMPurify from "isomorphic-dompurify";

export const linkUrl = (root: string, url?: string) => {
  if (!url) return url;

  if (url?.split('://')?.length > 1) return url;

  let protocol: string|null = null;
  
  let splitRoot = root.split('://');
  if (splitRoot?.length > 1) {
    protocol = splitRoot[0];
    root = splitRoot[1];
  }

  let link = `${root}/${url}`.replace(/\/+/g, '/');

  if(protocol) link = `${protocol}://${link}`;

  return link;
};

export const isLinkActive = (path?: string, linkPath?: string|string[], activePhrase?: string, exactPhrase?: string, subs?: string[]): string => {
  if(!path || !linkPath) return '';
  if(!Array.isArray(linkPath)) linkPath = [ linkPath ];

  for(let lp of linkPath) {
    if(path === lp) return exactPhrase ?? 'exact-match';

    const s = path.split(lp);
    if(
      s.length > 0
      && s[0] === ''
      && s?.[1]?.substring(0, 1) === '/'
    ) return activePhrase ?? 'active';
  }

  if(subs) {
    for(let sub of subs) {
      const subClass = isLinkActive(path, sub, activePhrase, exactPhrase);
      if(subClass !== '') return subClass;
    }
  }
  
  return '';
};

export const purify = (html?: string): { __html: string } => {
  return {
    __html: DOMPurify.sanitize(html ?? '', {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        'allow',
        'allowfullscreen',
        'frameborder',
        'scrolling',
        'target',
      ]
    })
  };
};

export const stripTags = (html?: string): string => {
  return DOMPurify.sanitize(html ?? '', { USE_PROFILES: { html: false } });
};

export const stripHTML = (content: string, wordCount?: number): string => {
  let str = '';
  if(typeof document !== 'undefined' && document?.createElement) {
    let temp = document?.createElement('DIV');
    if(temp) temp.innerHTML = content ?? '';
    str = temp?.textContent || temp?.innerText || '';
  } else {
    str = content?.replace(/(<([^>]+)>)/gi, '') ?? '';
  }

  if(wordCount) {
    str = str.split(' ').slice(0, wordCount).join(' ');
    if(str) str += '...';
  }

  return str ?? '';
};

export const gtmSender = (data: any, cnt?: number, retry?: number) => {
  const senderRetryDelay = 100;
  const senderRetryCount = retry ?? 100;
  
  if(!cnt) cnt = 0;
  if(window !== undefined && window?.dataLayer) {
    try {
      if(window?.dataLayer?.filter((item: any) => item?.event === 'gtm.load')?.length) {
        sendGTMEvent(data);
      } else {
        cnt++;
        if(cnt <= senderRetryCount) setTimeout(() => gtmSender(data, cnt), senderRetryDelay);
      }
    } catch(e) {
      cnt++;
      if(cnt <= senderRetryCount) setTimeout(() => gtmSender(data, cnt), senderRetryDelay);
    }
  } else {
    cnt++;
    if(cnt <= senderRetryCount) setTimeout(() => gtmSender(data, cnt), senderRetryDelay);
  }
};

export const rtbhSender = (data: Record<string,any>, cnt?: number, retry?: number) => {
  const senderRetryDelay = 100;
  const senderRetryCount = retry ?? 100;
  
  if(!cnt) cnt = 0;
  cnt++;
  if(window !== undefined) {
    try {
      if(!window?.rtbhEvents) window.rtbhEvents = [];
      if(!rtbhIsSame(data, window.rtbhEvents?.[window.rtbhEvents.length - 1])) window.rtbhEvents.push(data);
    } catch(e) {
      if(cnt <= senderRetryCount) setTimeout(() => rtbhSender(data, cnt), senderRetryDelay);
    }
  } else {
    if(cnt <= senderRetryCount) setTimeout(() => rtbhSender(data, cnt), senderRetryDelay);
  }
};

export const rtbhIsSame = (data: Record<string,any>, latest?: Record<string,any>) => {
  if(!latest) return false;

  for(let k in data) if(!valuesAreSame(data[k], latest?.[k])) return false;
  for(let k in latest) if(!valuesAreSame(latest[k], data?.[k])) return false;

  return true;
};

export const valuesAreSame = (first: any, second?: any) => {
  if(!second) return false;

  if(Array.isArray(first) && !Array.isArray(second)) return false;
  if(Array.isArray(second) && !Array.isArray(first)) return false;
  
  if(Array.isArray(first)) {
    if(first.length !== second?.length) return false;
    for(let k in first) if(first[k] !== second[k]) return false;
  } else {
    if(first !== second) return false;
  }

  return true;
};